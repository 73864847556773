import Vue from 'vue'
import App from './App.vue'
import { encryptDes, decryptDes } from './des.js'

Vue.config.productionTip = false

Vue.prototype.encryptDes=encryptDes
Vue.prototype.decryptDes = decryptDes

new Vue({
  render: h => h(App),
}).$mount('#app')
