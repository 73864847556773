<template>
  <div id="app">
    <div class='tableBox'>
		<h5>{{dateTime}}   预定安排</h5>
		<h6>{{place | placeFilter}}</h6>
		<table>
			<thead>
				<tr>
					<th>时间</th>
					<th>案号/备注</th>
					<th>仲裁员</th>
					<th>用途</th>
					<th>预定人</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for='(item,index) of tableData' :key='index'>
					<td>{{item.startDate}}-{{item.endDate}}</td>
					<td>{{item.caseCode}}</td>
					<td>{{item.arbitrator}}</td>
					<td>{{item.use}}</td>
					<td>{{item.createUser}}</td>
				</tr>
			</tbody>
		</table>
	</div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'App',
  data(){
	return {
		dateTime:'',
		tableData:[],
		place:'',
		key:'sciatest'
	}  
  },
  methods:{
	listHandler(){
		console.log(this.dateTime)
		let jiami = this.encryptDes(`{"queryDate":"${this.dateTime}"}`,this.key)
		let _this = this
		axios.post('http://dev-demo.goldenyun.cn/api/Webcore/querySittingInfo',{params:jiami})
		.then(function (res) {
			if(res.data.success==1){
				let placeArr = res.data.result[0].place
				_this.place = placeArr
				placeArr = res.data.result.filter(item => {
					return placeArr.indexOf(item.place)>-1
				})
				_this.tableData = placeArr
			}
		})
		.catch(function (error) {
			throw error;
		})
	}  
  },
  filters:{
	placeFilter(val){
		let name = ''
		switch(val){
			case '0011':
					name = '深交所第一仲裁庭'
				break
			case '0012':
					name = '深交所第二仲裁庭'
				break;
			case '0013':
					name = '深交所第三仲裁庭'
				break;
			case '0014':
					name = '深交所第四仲裁庭'
				break;
			case '0015':
					name = '深交所第五仲裁庭'
				break;
			case '0016':
					name = '深交所第六仲裁庭'
				break;
			case '0017':
					name = '深交所第七仲裁庭'
				break;
			case '0018':
					name = '深交所第八仲裁庭'
				break;
			default:
		}
		return name
	}  
  },
  created(){
	// 日期
	let date = new Date();
	this.dateTime = date.getFullYear() + '-' + (date.getMonth()+1) + '-' +date.getDate()
  },
  mounted(){
	setInterval(this.listHandler(),1000*60*30)
  }
}
</script>

<style lang='scss'>
*{
	margin: 0;
	padding:0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.tableBox{
	width:100%;
	border: 1px solid #ccc;
	h5,h6{
		border-bottom: 1px solid #ccc;
		margin: 0;
		font-weight: normal;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	h5{
		background:#10bbb5;
	}
	table{
		width:100%;
		height:auto;
		overflow: hidden;
		border: 1px solid #ccc;
		border-collapse: collapse;
		font-size:14px;
		thead{
			background:#efefef;
			
		}
		th,td{
			border: 1px solid #ccc;
		}
		thead{
			th{
				line-height:30px;
				font-weight: normal;
			}
		}
		tbody{
			td{
				height:36px;
			}
		}
	}
}
</style>
